<template>
  <el-dialog
    id="CopyContractDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
  >
    <el-form ref="CopyContractForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex">
        <el-empty :image-size="60" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="name" :label="$t('Назва нового договору')">
          <el-input v-model="model.name" ref="nameInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="beginDate" :label="$t('Початок')">
              <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.beginDate" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="endDate" :label="$t('Кінець')">
              <el-date-picker type="date" format="dd.MM.yyyy" v-model="model.endDate" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";

  export default {
    name: "CopyContract",
    components: {},
    data() {
      var that = this;
      var defaultValidateRule = { required: true, message: $tt("Це поле є необхідним") };
      var checkBeginDate = (rule, value, callback) => {
        if (value && that.model.endDate) {
          if (new Date(value) > new Date(that.model.endDate)) callback(new Error("Дата початку пізніше дати кінця"));
        }
        callback();
      };
      var checkEndDate = (rule, value, callback) => {
        if (value && that.model.beginDate) {
          if (new Date(value) < new Date(that.model.beginDate)) callback(new Error("Дата кінця раніше дати початку"));
        }
        callback();
      };
      return {
        loading: false,
        dialogShow: false,
        title: "Створення копії договору",
        operation: null,
        model: {},
        initModel: {},
        rules: {
          name: [defaultValidateRule],
          beginDate: [
            defaultValidateRule,
            {
              validator: checkBeginDate,
            },
          ],
          endDate: [
            defaultValidateRule,
            {
              validator: checkEndDate,
            },
          ],
        },
      };
    },

    methods: {
      onCommit() {
        this.$refs.CopyContractForm.validate((valid) => {
          if (valid) {
            this.$emit("commit", { model: this.model, operation: "copy" });
          } else {
            this.$message.error($tt("Перевірте введені дані!"));
          }
          return false;
        });
      },
      onShow() {
        this.$refs.CopyContractForm.resetFields();
        this.model = _.extend({}, this.initModel);
        this.$nextTick(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.nameInput.focus();
          });
        });
      },
    },
    created() {
      bus.$on("forms.contracts.copy.show", (data) => {
        this.loading = true;
        this.title = (data && data.title) || this.title;
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.dialogShow = true;
      });
      bus.$on("forms.contracts.copy.hide", () => {
        this.dialogShow = false;
      });
    },
    computed: {},
  };
</script>
<style lang="scss">
  #CopyContractDialog {
    .el-dialog {
      max-height: 280px !important;
    }
  }
</style>
